<template>
  <div class="card" :class="bgProgress == 'green' ? 'green' : ''">
    <div
      class="is-flex js-between ai-center"
      v-if="nameSubject != '' && nameSubject != undefined"
    >
      <p class="color-00 font-16 mg-b-6 cursor-pointer set-name-sub">
        {{ nameSubject }} ({{ courseId }})
      </p>

      <!-- {{ favorite }} -->
      <i
        class="fas fa-star color-star-light cursor-pointer"
        @click="changeFavoriteDel(subjectId)"
        v-if="favorite"
      ></i>
      <i
        class="fas fa-star color-star-gray cursor-pointer"
        @click="changeFavoriteAdd(subjectId)"
        v-else
      ></i>
    </div>
    <el-progress
      :show-text="false"
      :percentage="percentNow"
      :stroke-width="strokeWidth"
      :color="bgProgress == 'green' ? '#00D8A8' : '#00D8A8'"
    ></el-progress>
    <div class="is-flex js-between">
      <p class="show-per" v-if="totalHour > 1">
        {{ nowHour + "/" + totalHour + " Learning hours" }}
      </p>
      <p class="show-per" v-else>
        {{ nowHour + "/" + totalHour + " Learning hour" }}
      </p>
      <p class="show-per">{{ percentNow + "/" + "100%" }}</p>
    </div>
  </div>
</template>
<script>
import { HTTP } from "@/service/axios";
export default {
  props: [
    "nowHour",
    "totalHour",
    "percentNow",
    "bgProgress",
    "nameSubject",
    "countSubject",
    "courseId",
    "strokeWidth",
    "favorite",
    "subjectId",
  ],
  computed: {
    user() {
      return this.$store.state.user;
    },
  },

  methods: {
    changeFavoriteDel(id) {
      console.log("id", id);

      let delFav = {
        subjectId: id,
        favorite: false,
      };

      HTTP.defaults.headers.common.Authorization =
        `Bearer ` + this.user.accessToken;
      HTTP.post(`save/favorite`, delFav)
        .then((res) => {
          if (res.data.success) {
            console.log("ok del");
            this.$emit("updateStatus", 1);
          }
        })
        .catch((e) => {
          console.log(e);
          this.alertCatchError(e.response.status);
        });
    },

    changeFavoriteAdd(id) {
      console.log("id", id);

      let addFav = {
        subjectId: id,
        favorite: true,
      };

      HTTP.defaults.headers.common.Authorization =
        `Bearer ` + this.user.accessToken;
      HTTP.post(`save/favorite`, addFav)
        .then((res) => {
          if (res.data.success) {
            // this.getSubjectLearning();
            // this.getFavorite();
            // this.getSubject();
            console.log("ok add");
            this.$emit("updateStatus", 1);
          }
        })
        .catch((e) => {
          console.log(e);
          this.alertCatchError(e.response.status);
        });
    },
  },
};
</script>
