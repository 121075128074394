<template>
  <div id="all-page">
    <defaultTemplate>
      <div class="pd-x-5 pd-y-3">
        <el-breadcrumb
          v-if="subjectn"
          separator-class="el-icon-arrow-right"
          class="is-flex ai-center"
        >
          <el-breadcrumb-item :to="{ path: `/learning/semester/${SemesterId}` }"
            >All</el-breadcrumb-item
          >
          <el-breadcrumb-item> {{ subjectn.namesubject }} </el-breadcrumb-item>
        </el-breadcrumb>

        <div
          class="is-flex js-between mg-y-5 font-14 font-weight-400"
          v-if="Semester"
        >
          <p>{{ "Semester " + Semester.namesemester }}</p>
          <p>Subject : 1</p>
        </div>

        <CardSubject
          v-if="subjectn"
          :nowHour="getBadgeHourSum(subjectn)"
          :totalHour="subjectn.hour"
          :percentNow="percentNow(getBadgeHourSum(subjectn), subjectn.hour)"
          :nameSubject="subjectn.namesubject"
          :bgProgress="`light`"
        />

        <div class="is-flex js-between border-b pd-t-5" v-if="subjectn">
          <p class="font-16 text-dark pd-t-5 font-weight-500">
            {{ "Badge " + subjectn.abbreviations }}
          </p>
          <p class="font-14 color-70 pd-t-5">
            {{ subjectn.badges.length + " Badge" }}
          </p>
        </div>

        <div class="mg-y-5"></div>

        <!-- <CardProgress
          v-if="subject"
          :nowHour="nowHour(subject.badge)"
          :totalHour="getTotalHour(subject.badge)"
          :percentNow="
            percentNow(getTotalHour(subject.badge), nowHour(subject.badge))
          "
          :nameSubject="subject.name"
          :bgProgress="`light`"
        />
        <p class="font-16 mg-t-3 color-35 font-weight-500 mg-b-5">Badge DTF</p> -->

        <!-- 
          <el-collapse v-if="subject" v-model="activeName" accordion>
          <div
            class="pd-t-7"
            v-for="(data, index) in  "
            :key="index"
          >
            <el-collapse-item :name="data.id" class="1234">
              <template slot="title">
                <div class="custom-title">
                  <p>{{ data.name }}</p>
                
                  <span
                    :class="
                      data.totalHour == getHour(data.detail) ? 'success' : ''
                    "
                  >
                    <span v-if="data.totalHour == getHour(data.detail)">
                      <i class="fas fa-check-circle mg-r-7"></i>
                      <span>{{ getHour(data.detail) }}</span>
                    </span>
                    <span v-else>
                      {{ getHour(data.detail) + "/" + data.totalHour }}
                    </span>
                    Learning hour
                  </span>
                </div>
              </template>
              <div
                class="detail-badge"
                :class="badge.certificate == true ? 'cursor-pointer' : ''"
                v-for="(badge, index) in data.detail"
                :key="index"
                @click="changePage(badge.certificate, badge.id)"
              >
                <div class="is-flex ai-center js-between">
                  <span>
                    {{ badge.name }}
                  </span>
                  <i class="fas fa-chevron-right" v-if="badge.certificate"></i>
                </div>
                <div class="is-flex ai-center js-between mg-t-7">
                  <div>
                    <span class="circle-green"></span>
                    {{ badge.type }}
                  </div>
                  <span class="color-green">
                    {{ badge.learningHour }} Learning hour
                  </span>
                </div>
              </div>
            </el-collapse-item>
          </div>
        </el-collapse>
         -->

        <carousel
          :responsive="responsive"
          :nav="false"
          v-if="subjectn"
          :touchDrag="false"
          :mouseDrag="false"
        >
          <div
            class="card mg-6"
            v-for="(badgeAll, index) in subjectn.badges"
            :key="'badges' + index"
            @click="selectBadgeFun('badges_' + index)"
          >
            <div class="is-flex ai-center">
              <i class="el-icon-circle-check"></i>
              <p class="font-12 mg-l-7">
                <span v-if="badgeAll.history != undefined">
                  {{ getHour(badgeAll.history) }}
                </span>
                <span v-else>0</span>
                /{{ badgeAll.hour }} Learning hour
              </p>
            </div>
            <div class="mg-y-5">
              <CirclePrecess
                :percentage="
                  parseInt(
                    (badgeAll.history != undefined
                      ? getHour(badgeAll.history) * 100
                      : 0) / badgeAll.hour
                  )
                "
                :color="`#353535`"
                :strokewidth="7"
                :width="80"
              />
            </div>
            <h4 class="mg-b-7">{{ badgeAll.namebadge }}</h4>
            <p class="mg-0">{{ badgeAll.status }}</p>
          </div>
        </carousel>
        <div class="is-flex js-between pd-t-5">
          <p class="font-16 text-dark pd-t-5 font-weight-500">
            {{ selectBadge.namebadge }}
          </p>
          <p class="font-14 color-70 pd-t-5">
            {{ getHour(selectBadge.history) + " Learning hour" }}
          </p>
        </div>
        <div
          v-for="(history, index) in selectBadge.history"
          :key="'history' + index"
        >
          <a
            :href="`/subject/certificate/${history.id}`"
            class="cursor-pointer"
          >
            <div class="card mg-y-5">
              <div class="is-flex js-between ai-center">
                <div>
                  <p class="color-00 font-16 mg-b-0 set-name-sub">
                    {{ history.bsname }}
                  </p>
                  <p class="color-c4 mg-0 mg-t-7 show-per">
                    {{ history.learnninghour + " Learning hour" }}
                  </p>
                </div>
                <p class="color-c4 font-16 mg-b-6">
                  <i class="fas fa-chevron-right"></i>
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="is-flex js-between ai-center pd-t-5">
          <div>
            <p class="font-16 text-dark pd-0 font-weight-500">
              <i class="fas fa-award"></i> Learn more
            </p>
            <p class="font-16 text-dark pd-0 pd-l-5 mg-0 font-weight-500">
              {{ selectBadge.namebadge }}
            </p>
          </div>
          <p class="font-14 color-70 pd-t-5">
            {{ getHour(selectBadge.history) + " Learning hour" }}
          </p>
        </div>
        <div
          v-for="(learnmore, index) in selectBadge.historylearnmore"
          :key="index"
        >
          <a
            :href="`/subject/certificate/${learnmore.id}`"
            class="cursor-pointer"
          >
            <div class="card mg-y-5">
              <div class="is-flex js-between ai-center">
                <div>
                  <p class="color-00 font-16 mg-b-0 set-name-sub">
                    {{ learnmore.bsname }}
                  </p>
                  <p class="color-c4 mg-0 mg-t-7 show-per">
                    {{ learnmore.learnninghour + " Learning hour" }}
                  </p>
                </div>
                <p class="color-c4 font-16 mg-b-6">
                  <i class="fas fa-chevron-right"></i>
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </defaultTemplate>
  </div>
</template>
<script>
import defaultTemplate from "@/template/default.vue";
import CardSubject from "@/components/CardSubject.vue";
import Data from "@/db/data.json";
import carousel from "vue-owl-carousel";
import CirclePrecess from "@/components/circleProcess.vue";
export default {
  components: {
    defaultTemplate,
    CardSubject,
    Data,
    carousel,
    CirclePrecess,
  },
  mounted() {
    //this.getSubject();
    this.getBadge();
    //this.getBadgeHourSum();
  },
  data() {
    return {
      selectBadge: [],
      responsive: {
        // breakpoint from 0 up
        0: {
          items: 2,
          loop: true,
          // option1 : value,
          // option2 : value,
        },
        // breakpoint from 768 up
        767: {
          items: 2,
        },
      },
      loading: true,
      getHoura: 0,
      SemesterId: this.$route.params.idSemester,
      SubjectId: this.$route.params.id,
      data: Data,
      activeName: 1,
      badge: "",
      subject: null,
      subjectn: null,
      Semester: null,

      bb: null,
    };
  },
  methods: {
    selectBadgeFun(index) {
      let res = index.split("_");
      this.selectBadge = this.subjectn.badges[res[1]];
      console.log("selectBadge",index);
    },
    getBadge() {
      let semester = this.data.user.semester.filter(
        (semester) => this.SemesterId == semester.id
      )[0];
      this.Semester = typeof semester != "undefined" ? semester : [];
      // console.log(this.Semester, "data semester ");
      this.subjectn = this.Semester.subject.filter(
        (sj) => this.SubjectId == sj.id
      )[0];
      // console.log(this.subjectn, " this.subject");
      // หา badges subject ในเทอมนั้นๆ

      this.selectBadge = this.subjectn.badges[0];
      this.loading = false;
    },

    getHour(dataHour) {
      let total = 0;
      if (dataHour !== undefined) {
        for (let i = 0; i < dataHour.length; i++) {
          total += dataHour[i].learnninghour;
        }
        return total;
      }
    },
    getBadgeHourSum(data) {
      let learnHour = 0;
      data.badges.forEach((item) => {
        item.history.forEach((hour) => {
          learnHour = hour.learnninghour + learnHour;
        });
      });
      return learnHour;
    },

    percentNow(toTalHour, nowHour) {
      let percentNow = (toTalHour * 100) / nowHour;
      return Number(percentNow.toFixed(0));
    },
    // nowHour(badge) {
    //   let nowtotal = 0;
    //   badge.map((item) => {
    //     let nowhour = this.getHour(item.learnninghour);
    //     nowtotal = nowhour + nowtotal;
    //   });
    //   console.log(nowtotal);
    //   return nowtotal;
    // },
    // getTotalHour(total) {
    //   let totalHour = 0;

    //   total.map((item) => {
    //     totalHour = item.totalHour + totalHour;
    //   });
    //   return totalHour;
    // },
  },
};
</script>
